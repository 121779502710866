import { getVm } from '@/helpers/vm-helper';
import type Vue from 'vue';
import { computed, getCurrentInstance, watch } from 'vue';

import { useLanguageStore, useRoutingStore, useUserStore } from '@/helpers/composables/useStores';

import { ObjectType, WatchNowOfferFilter } from '@/@types/graphql-types';
import { ImpressionTrackingEvents } from '@/enums/events';
import { TitleListName } from '@/interfaces/responses/title-list';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import { addTitleToListToast, getSeenToastMessage, removeTitleFromListToast } from '@/helpers/toast/toast-helper';
import { TrackingHelper, type TrackingListEventPayload } from '@/helpers/tracking/tracking-helper';
import { toValue, type MaybeRefOrGetter } from '@vueuse/core';
import { SnowplowTitleContextGraphql, type SnowplowContext } from '@/helpers/tracking/providers';
import { ModalHelper } from '@/helpers/modal-helper';

import {
	SetInWatchlistDocument,
	SetInWatchlistMutation,
	SetInWatchlistMutationVariables,
} from '@/graphql/mutation/SetInWatchlist.mutation';
import {
	SetInDislikelistDocument,
	SetInDislikelistMutation,
	SetInDislikelistMutationVariables,
} from '@/graphql/mutation/SetInDislikelist.mutation';
import {
	SetInLikelistDocument,
	SetInLikelistMutation,
	SetInLikelistMutationVariables,
} from '@/graphql/mutation/SetInLikelist.mutation';

import {
	SetInSeenlistDocument,
	SetInSeenlistMutation,
	SetInSeenlistMutationVariables,
} from '@/graphql/mutation/SetInSeenlist.mutation';

import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import type {
	ListMutationShowDetailParam,
	ListMutationTitleDetailParam,
	TitleQuickActionPayload,
} from '@/helpers/providers/title-actions-provider';
import type { DollarApollo } from 'vue-apollo/types/vue-apollo';
import LikeDislikePollModal from '@/components/modals/LikeDislikePollModal.vue';

interface UseListActionsOptions {
	sponsoredAd: MaybeRefOrGetter<SponsoredAdFragment | undefined>;
	additionalContexts?: MaybeRefOrGetter<SnowplowContext[]>;
}

export function useListActions({ sponsoredAd, additionalContexts = [] }: UseListActionsOptions) {
	const { activeRoute } = useRoutingStore();
	watch(activeRoute, () => process.client && localStorage.removeItem('notifyCallback'));

	const isSponsoredRecommendationCohort = computed(() => !toValue(sponsoredAd)?.holdoutGroup);
	const sponsoredRecommendationPackageId = computed(
		() => toValue(sponsoredAd)?.campaign?.watchNowOffer.package.packageId
	);

	const instance = computed(() => {
		const { proxy: instance } = getCurrentInstance() ?? { proxy: getVm() };
		return instance as {
			$apollo: DollarApollo<typeof instance>;
			$emit: (event: string, ...args: any[]) => Vue;
		};
	});

	const { isLoggedIn } = useUserStore();
	/** IMPORTANT: implement this with a @Watch('isLoggedIn') in the component that requires a login callback. Example in BuyBox.vue */
	async function onIsLoggedInChange(
		titleId: string,
		details: MaybeRefOrGetter<ListMutationTitleDetailParam>,
		isTitleInWatchlist: MaybeRefOrGetter<boolean>,
		source: string
	) {
		if (!process.client) return;

		if (isLoggedIn.value && localStorage.getItem('notifyCallback') === titleId?.toString()) {
			setInWatchlist(titleId, isTitleInWatchlist, details, { source });
			localStorage.removeItem('notifyCallback');
		}
	}

	const { country, language } = useLanguageStore();
	async function setInWatchlist(
		titleId: string,
		isTitleInList: MaybeRefOrGetter<boolean>,
		titleDetails: MaybeRefOrGetter<ListMutationTitleDetailParam>,
		trackingPayload?: {
			source?: string;
			property?: string;
		}
	) {
		if (process.client && !isLoggedIn.value) {
			localStorage.setItem('notifyCallback', titleId?.toString());
		}

		if (await preventNonSignedInAction()) {
			return null;
		}

		const isInWatchlist = toValue(isTitleInList);
		const { objectId, objectType, contentType } = toValue(titleDetails);
		const titleDetail = toValue(titleDetails);

		// Currently unsupported
		if (objectType === ObjectType.ShowSeason) return;

		const data = await instance.value.$apollo.mutate<SetInWatchlistMutation, SetInWatchlistMutationVariables>({
			mutation: SetInWatchlistDocument,
			variables: {
				input: {
					id: titleId,
					state: !isInWatchlist,
				},
				country: country.value,
			},
			update: () => {
				const contexts = [...toValue(additionalContexts)];

				if (!contexts.some(({ __name }) => __name.includes('title'))) {
					contexts.push(new SnowplowTitleContextGraphql(objectId, objectType, null, null, contentType));
				}

				if (trackingPayload) {
					const payload: TrackingListEventPayload = {
						action: trackingPayload.source ?? '',
						property: trackingPayload.property,
					};

					const action = isInWatchlist ? 'remove' : 'add';
					TrackingHelper.trackListEvent(TitleListName.WATCHLIST, action, payload, contexts);
				}

				if (!isInWatchlist && toValue(sponsoredAd)?.campaign?.node.nodeId === titleId) {
					TrackingHelper.trackEvent(
						ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS,
						{
							action: 'watchlist_clicked',
							label: `${titleId}_${sponsoredRecommendationPackageId.value}`,
							property: isSponsoredRecommendationCohort.value ? 'ad' : 'poster',
							value: isSponsoredRecommendationCohort.value ? 1 : 0,
						},
						contexts
					);
				}

				if (!isInWatchlist) {
					addTitleToListToast(TitleListName.WATCHLIST, titleDetail, () =>
						setInWatchlist(titleId, true, titleDetails, trackingPayload)
					);
				} else {
					removeTitleFromListToast(TitleListName.WATCHLIST, titleDetail);
				}
			},
		});

		instance.value.$emit('title-quick-action', <TitleQuickActionPayload>{
			type: TitleListName.WATCHLIST,
			isTitleInList: isInWatchlist,
			objectType,
			titleObjectId: objectId,
		});

		return data;
	}

	async function setInDislikelist(
		titleId: string,
		isTitleInDislikeList: MaybeRefOrGetter<boolean>,
		isTitleInLikeList: MaybeRefOrGetter<boolean>,
		titleDetails: MaybeRefOrGetter<ListMutationTitleDetailParam>,
		trackingPayload?: {
			source?: string;
			property?: string;
		},
		onComplete?: () => void
	) {
		if (await preventNonSignedInAction()) {
			return null;
		}

		const isInDislikeList = toValue(isTitleInDislikeList);
		const isInLikeList = toValue(isTitleInLikeList);

		const { objectId, objectType, seasonNumber, episodeNumber, contentType } = toValue(titleDetails);
		const titleDetail = toValue(titleDetails);

		const data = await instance.value.$apollo.mutate<SetInDislikelistMutation, SetInDislikelistMutationVariables>({
			mutation: SetInDislikelistDocument,
			variables: {
				input: {
					id: titleId,
					state: !isInDislikeList,
				},
			},
			update: () => {
				const contexts = [...toValue(additionalContexts)];

				if (!contexts.some(({ __name }) => __name.includes('title'))) {
					contexts.push(
						new SnowplowTitleContextGraphql(objectId, objectType, seasonNumber, episodeNumber, contentType)
					);
				}

				onComplete?.();

				if (trackingPayload) {
					const payload: TrackingListEventPayload = {
						action: trackingPayload?.source ?? '',
						property: trackingPayload.property,
					};

					if (isInLikeList) {
						TrackingHelper.trackListEvent(TitleListName.LIKELIST, 'remove', payload, contexts);
					}

					const action = isInDislikeList ? 'remove' : 'add';
					TrackingHelper.trackListEvent(TitleListName.DISLIKELIST, action, payload, contexts);
				}

				if (!isInDislikeList) {
					addTitleToListToast(TitleListName.DISLIKELIST, titleDetail, () =>
						setInDislikelist(titleId, true, false, titleDetails, trackingPayload)
					);
				} else {
					removeTitleFromListToast(TitleListName.DISLIKELIST, titleDetail);
				}
			},
		});

		instance.value.$emit('title-quick-action', <TitleQuickActionPayload>{
			titleObjectId: objectId,
			type: TitleListName.DISLIKELIST,
			isTitleInList: isInDislikeList,
			objectType: objectType,
		});

		return data;
	}

	async function setInLikelist(
		titleId: string,
		isTitleInLikeList: MaybeRefOrGetter<boolean>,
		isTitleInDislikeList: MaybeRefOrGetter<boolean>,
		titleDetails: MaybeRefOrGetter<ListMutationTitleDetailParam>,
		trackingPayload?: {
			source?: string;
			property?: string;
		},
		onComplete?: () => void
	) {
		if (await preventNonSignedInAction()) {
			return null;
		}

		const isInLikeList = toValue(isTitleInLikeList);
		const isInDislikeList = toValue(isTitleInDislikeList);

		const { objectId, objectType, seasonNumber, episodeNumber, contentType } = toValue(titleDetails);
		const titleDetail = toValue(titleDetails);

		const data = await instance.value.$apollo.mutate<SetInLikelistMutation, SetInLikelistMutationVariables>({
			mutation: SetInLikelistDocument,
			variables: {
				input: {
					id: titleId,
					state: !isInLikeList,
				},
			},
			update: () => {
				const contexts = [...toValue(additionalContexts)];
				if (!contexts.some(({ __name }) => __name.includes('title'))) {
					contexts.push(
						new SnowplowTitleContextGraphql(objectId, objectType, seasonNumber, episodeNumber, contentType)
					);
				}

				onComplete?.();

				if (trackingPayload) {
					const payload: TrackingListEventPayload = {
						action: trackingPayload.source ?? '',
						property: trackingPayload.property,
					};

					if (isInDislikeList) {
						TrackingHelper.trackListEvent(TitleListName.DISLIKELIST, 'remove', payload, contexts);
					}

					const action = isTitleInLikeList ? 'remove' : 'add';
					TrackingHelper.trackListEvent(TitleListName.LIKELIST, action, payload, contexts);
				}

				if (!isTitleInLikeList) {
					addTitleToListToast(TitleListName.LIKELIST, titleDetail, () =>
						setInLikelist(titleId, true, false, titleDetails, trackingPayload)
					);
				} else {
					removeTitleFromListToast(TitleListName.LIKELIST, titleDetail);
				}
			},
		});

		instance.value.$emit('title-quick-action', <TitleQuickActionPayload>{
			titleObjectId: objectId,
			type: TitleListName.LIKELIST,
			isTitleInList: isInLikeList,
			objectType: objectType,
		});

		return data;
	}

	async function setInSeenlist(
		titleId: string,
		isTitleInList: MaybeRefOrGetter<boolean>,
		titleDetails: MaybeRefOrGetter<ListMutationTitleDetailParam & ListMutationShowDetailParam>,
		// This is only for set in seenlist WatchNextEpisode
		// When we set as seen WatchNextEpisode, we get whole show in response that graphql uses to update existing show cache
		// It will update existing show cache with new WatchNextEpisode, that has to contain correct WatchNowOffer (with correct filters applied)
		watchNowFilter: WatchNowOfferFilter = {},
		trackingPayload?: {
			source?: string;
			property?: string;
		},
		refetchQueryList?: string[]
	) {
		if (await preventNonSignedInAction()) {
			return null;
		}

		const isInSeenList = toValue(isTitleInList);
		const {
			showObjectId,
			objectId,
			objectType,
			title: titleName,
			seasonNumber,
			episodeNumber,
			contentType,
		} = toValue(titleDetails);
		const titleDetail = toValue(titleDetails);

		const data = await instance.value.$apollo.mutate<SetInSeenlistMutation, SetInSeenlistMutationVariables>({
			mutation: SetInSeenlistDocument,
			variables: {
				input: {
					id: titleId,
					state: !isInSeenList,
					country: country.value,
				},
				country: country.value,
				language: language.value,
				watchNowFilter: watchNowFilter,
				includeUnreleasedEpisodes: false,
			},
			refetchQueries: refetchQueryList,
			update: () => {
				const contexts = [...toValue(additionalContexts)];
				if (!contexts.some(({ __name }) => __name.includes('title'))) {
					contexts.push(
						new SnowplowTitleContextGraphql(
							showObjectId || objectId,
							objectType === ObjectType.ShowSeason ? ObjectType.Show : objectType,
							seasonNumber,
							episodeNumber,
							contentType
						)
					);
				}

				if (trackingPayload) {
					const payload: TrackingListEventPayload = {
						action: trackingPayload.source ?? '',
						property: trackingPayload.property,
					};

					const action = isInSeenList ? 'remove' : 'add';
					TrackingHelper.trackListEvent(TitleListName.SEENLIST, action, payload, contexts);
				}

				if (!isInSeenList) {
					if (objectType === ObjectType.Show) {
						ModalHelper.openModal(
							LikeDislikePollModal,
							{
								closable: true,
								modalTitle: getSeenToastMessage(isInSeenList, {
									titleName,
									titleObjectType: objectType,
									showSeason: seasonNumber,
									showEpisode: episodeNumber,
								}),
								titleId,
								titleDetails,
							},
							{ cssClass: 'jw-poll-modal', id: 'like_dislike_poll_modal' }
						);
					} else {
						addTitleToListToast(TitleListName.SEENLIST, titleDetail);
					}
				} else {
					removeTitleFromListToast(TitleListName.SEENLIST, titleDetail);
				}
			},
		});

		instance.value.$emit('title-quick-action', <TitleQuickActionPayload>{
			titleObjectId: objectId,
			type: TitleListName.SEENLIST,
			isTitleInList,
			objectType,
		});

		return data;
	}

	return {
		isSponsoredRecommendationCohort,
		sponsoredRecommendationPackageId,
		onIsLoggedInChange,
		setInWatchlist,
		setInDislikelist,
		setInLikelist,
		setInSeenlist,
	};
}
