var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sponsored-pill radius-small p-1",class:[
		`sponsored-pill--${_vm.theme}`,
		{
			'top-position': _vm.topPosition,
			'bottom-position': !_vm.topPosition,
			'sponsored-pill--transparent': _vm.isTransparent,
		},
	],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _setup.clickHandler.apply(null, arguments)}}},[_c('div',{staticClass:"tooltip",attrs:{"data-balloon-pos":_setup.balloonPosition,"data-balloon-blunt":"","aria-label":_vm.$t('WEBAPP_NATIVE_RECOMMENDATION_AD_DESCRIPTION_GENERAL')}},[_c('p',{staticClass:"sponsored-pill__text"},[_vm._v(_vm._s(_vm.$t('WEBAPP_NATIVE_RECOMMENDATION_AD_TITLE')))]),_c('FontAwesomeIcon',{staticClass:"sponsored-pill__icon text-high",attrs:{"icon":"info-circle"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }