import type { AssertByTypename } from '@/helpers/graphql-model-helper';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

type CampaignNode = NonNullable<SponsoredAdFragment['campaign']>['node'];
export type SingleTitleSR = AssertByTypename<CampaignNode, 'Movie' | 'Show' | 'Season'>;
export type ListTitleSR = AssertByTypename<CampaignNode, 'GenericTitleList'>;

export function isListTitleSr(sr: SingleTitleSR | ListTitleSR): sr is ListTitleSR {
	return sr.__typename === 'GenericTitleList';
}

export type SRClickEvent<T> = {
	title: T;
	position: number;
};

/**
 * Used mainly for `@navigateTitle` and `@click` events on posters.
 * @info List Title SRs are required to include the title list ID.
 */
export type SRClickTitleEvent<T extends SingleTitleSR | ListTitleSR = SingleTitleSR | ListTitleSR> =
	T extends SingleTitleSR ? SRClickEvent<SingleTitleSR> : SRClickEvent<ListTitleSR> & { titleList: string };
